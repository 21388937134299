import logo from './logo.png';
import './App.css';

import { FaGithub } from "react-icons/fa";
import { FaLinkedin } from "react-icons/fa";
import { FaEnvelope } from 'react-icons/fa';

function App() {

    var openProject = "";
    const showProject = (project) => {
        var transitionPeriod = 500;
        var contentElement = document.getElementById(project + "-content");
        var contentHeight = contentElement.offsetHeight - 20; // 20 is the existing padding
        var element = document.getElementById(project);

        if (openProject === project) {
            element.style.height = "0px";
            setTimeout(function() {
                element.innerHTML = "";
                element.classList.remove('project-content-show');
                element.classList.add('project-content-hide');
            }, transitionPeriod);
            openProject = "";
        } else {
            var closingElements = false;
            Array.from(document.getElementsByClassName('project-content-show')).forEach(function(e) {
                closingElements = true;
                e.style.height = "0px";
                setTimeout(function() {
                    e.innerHTML = "";
                    e.classList.remove('project-content-show');
                    e.classList.add('project-content-hide');
                }, transitionPeriod);
            });
            var waitTime = closingElements ? transitionPeriod : 1;
            setTimeout(function() {
                element.classList.remove('project-content-hide');
                element.classList.add('project-content-show');
                element.innerHTML = contentElement.innerHTML;
                element.style.height = contentHeight + "px";
            }, waitTime);
            openProject = project;
        }
    }

    return (
        <div className="app">
            
            <div className="app-content">
                
                <header className="app-header">
                    <img src={logo} className="app-logo" alt="logo" />
                    <span className="app-title-line-1">Shoreside</span>
                    <div className="app-title-line-2">Software llc</div>
                </header>

                <div className="app-content-main-border"><div className="app-content-main">
                    <div className="app-content-description">
                        <div className="social-links">
                            <a className="social-link" target="blank" href='mailto:james@shoresidesoftware.com'>
                                <FaEnvelope />
                            </a>
                            <a className="social-link" target="blank" href="https://www.linkedin.com/in/james-griffiths-91aa4195/">
                                <FaLinkedin />
                            </a>
                            <a className="social-link" target="blank" href="https://github.com/jamesrgriffiths">
                                <FaGithub />
                            </a>
                        </div>

                        <div className="title">Welcome to Shoreside Software!</div>
                        I'm James, the owner and developer of this company. My 
                        journey started as a full stack developer in 2012 at a 
                        company on Long Island in New York while I was pursuing 
                        my degree in Computer Science. I earned my degree in 2013 
                        and continued working as a full stack developer at the 
                        same company until 2017 when I went out as an independent 
                        contractor. I now operate as an LLC and am fully insured. 
                        I love the challenges of finding better solutions, solving 
                        problems, and fine-tuning existing workflows. I offer 
                        various services from trouble shooting, testing, code 
                        refactoring or rebuilding, building web apps, database 
                        management, designing and setting up APIs and data flow 
                        automations, and everything in between. My pricing 
                        structure varies based on the scope of the project, and 
                        I'll always do my best to find a solution that can work 
                        for you. Contact me to discuss how Shoreside Software 
                        can improve your business.
                        
                        <br/><br/>

                        <span className="tech-pill tech-backend">PHP</span>
                        <span className="tech-pill tech-backend">Laravel</span>
                        <span className="tech-pill tech-backend">Java</span>
                        <span className="tech-pill tech-backend">Spring MVC</span>
                        <span className="tech-pill tech-backend">Python</span>
                        <span className="tech-pill tech-backend">ColdFusion</span>
                        <span className="tech-pill tech-backend">Node.js</span>
                        <span className="tech-pill tech-backend">MySQL</span>
                        <span className="tech-pill tech-backend">PostgreSQL</span>

                        <span className="tech-pill tech-frontend">Javascript</span>
                        <span className="tech-pill tech-frontend">Vue.js</span>
                        <span className="tech-pill tech-frontend">React</span>
                        <span className="tech-pill tech-frontend">JQuery</span>
                        <span className="tech-pill tech-frontend">HTML</span>
                        <span className="tech-pill tech-frontend">CSS</span>
                        <span className="tech-pill tech-frontend">Bootstrap</span>

                        <span className="tech-pill tech-other">Linux</span>
                        <span className="tech-pill tech-other">Docker</span>
                        <span className="tech-pill tech-other">Puppeteer</span>
                        <span className="tech-pill tech-other">Apache</span>
                        <span className="tech-pill tech-other">NGINX</span>
                        <span className="tech-pill tech-other">AWS</span>  
                        <span className="tech-pill tech-other">Digital Ocean</span>
                        <span className="tech-pill tech-other">Laravel Forge</span>                    
                        <span className="tech-pill tech-other">Stripe</span> 
                        <span className="tech-pill tech-other">8th Wall</span>
                        <span className="tech-pill tech-other">dotcom-monitor</span>
                        <span className="tech-pill tech-other">Fortify on Demand</span>
                        <span className="tech-pill tech-other">Zoho Creator</span>
                        
                    </div>

                    <div id="projects" className="projects">
                        <div className="title">
                            Projects:
                            <div className="subtitle">Click below to see more detail</div>
                        </div>

                        <ul>
                            <li onClick={() => showProject('project-agentbuzz')}>
                                AgentBuzz
                                <div id='project-agentbuzz-content' className="project-content">
                                    This was a client project where I rebuilt the 
                                    infrastructure and added in various new 
                                    capabilities. The primary functions allow a user 
                                    to purchase a subscription through a Stripe 
                                    Integration, create custom banners with text and 
                                    voice capable phone numbers and QR codes, and 
                                    then monitor incoming leads. 
                                    <br/><br/>
                                    December 2020 - November 2022
                                    <br/><br/>
                                    <span className="tech-pill tech-backend">PHP</span>
                                    <span className="tech-pill tech-backend">Laravel</span>
                                    <span className="tech-pill tech-backend">Node.js</span>
                                    <span className="tech-pill tech-backend">MySQL</span>
                                    <span className="tech-pill tech-frontend">Vue.js</span>
                                    <span className="tech-pill tech-frontend">HTML</span>
                                    <span className="tech-pill tech-frontend">CSS</span>
                                    <span className="tech-pill tech-other">Stripe</span>                             
                                </div>
                                <div id='project-agentbuzz' className="project-content-hide"></div>
                            </li>
                            <li onClick={() => showProject('project-educatius')}>
                                Educatius
                                <div id='project-educatius-content' className="project-content">
                                    This is a current client in the Education sector 
                                    where I create and manage various automations for 
                                    transferring data between various systems. I 
                                    utilize several small APIs, Puppeteer automations, 
                                    Python and Node JS scripts, and have it all running 
                                    off of a PHP Laravel and MySQL backend. I also 
                                    built a small interface for managing some pieces 
                                    of the system. 
                                    <br/><br/>
                                    February 2018 - Present
                                    <br/><br/>
                                    <span className="tech-pill tech-backend">PHP</span>
                                    <span className="tech-pill tech-backend">Laravel</span>
                                    <span className="tech-pill tech-backend">Python</span>
                                    <span className="tech-pill tech-backend">Node.js</span>
                                    <span className="tech-pill tech-backend">MySQL</span>
                                    <span className="tech-pill tech-frontend">Javascript</span>
                                    <span className="tech-pill tech-frontend">HTML</span>
                                    <span className="tech-pill tech-frontend">CSS</span>
                                    <span className="tech-pill tech-frontend">Bootstrap</span>
                                    <span className="tech-pill tech-other">Zoho Creator</span>
                                    <span className="tech-pill tech-other">Puppeteer</span>
                                    <span className="tech-pill tech-other">APIs</span>
                                </div>
                                <div id='project-educatius' className="project-content-hide"></div>
                            </li>
                            <li onClick={() => showProject('project-mc3')}>
                                MC3
                                <div id='project-mc3-content' className="project-content">
                                    This is a current client where I collaborate with 
                                    a team to create interactive experiences for events 
                                    in the healthcare sector. We create various modules 
                                    and have a management console that we update regularly. 
                                    I'm involved in the full stack operation here including 
                                    testing and evaluation of projects.
                                    <br/><br/>
                                    August 2022 - Present
                                    <br/><br/>
                                    <span className="tech-pill tech-backend">PHP</span>
                                    <span className="tech-pill tech-backend">MySQL</span>
                                    <span className="tech-pill tech-frontend">Javascript</span>
                                    <span className="tech-pill tech-frontend">JQuery</span>
                                    <span className="tech-pill tech-frontend">HTML</span>
                                    <span className="tech-pill tech-frontend">CSS</span>
                                    <span className="tech-pill tech-other">8th Wall</span>
                                    <span className="tech-pill tech-other">dotcom-monitor</span>
                                    <span className="tech-pill tech-other">Fortify on Demand</span>
                                </div>
                                <div id='project-mc3' className="project-content-hide"></div>
                            </li>
                            <li onClick={() => showProject('project-shoresidesoftware')}>
                                Shoreside Software
                                <div id='project-shoresidesoftware-content' className="project-content">
                                    The site you're on. It's a simple site 
                                    I built over a weekend to update my portfolio and 
                                    get myself more aquainted with React.
                                    <br/><br/>
                                    Last Updated September 2024
                                    <br/><br/>
                                    <span className="tech-pill tech-backend">Node.js</span>
                                    <span className="tech-pill tech-frontend">React</span>
                                    <span className="tech-pill tech-frontend">HTML</span>
                                    <span className="tech-pill tech-frontend">CSS</span>
                                    <span className="tech-pill tech-other">Docker</span>
                                </div>
                                <div id='project-shoresidesoftware' className="project-content-hide"></div>
                            </li>
                            <li onClick={() => showProject('project-supersimplecheckbook')}>
                                Super Simple Checkbook
                                <div id='project-supersimplecheckbook-content' className="project-content">
                                    This is a web app I built for finance management. 
                                    I built in with a typical PHP Laravel stack with 
                                    a MySQL database. It has not been updated in a 
                                    several years but is still available with limited 
                                    functionality below:
                                    <br/><br/>
                                    <a className='app-link' href='https://www.supersimplecheckbook.com' target='blank'>supersimplecheckbook.com</a>
                                    <br/><br/>
                                    Last Updated February 2019
                                    <br/><br/>
                                    <span className="tech-pill tech-backend">PHP</span>
                                    <span className="tech-pill tech-backend">Laravel</span>
                                    <span className="tech-pill tech-backend">Node.js</span>
                                    <span className="tech-pill tech-backend">MySQL</span>
                                    <span className="tech-pill tech-frontend">Javascript</span>
                                    <span className="tech-pill tech-frontend">JQuery</span>
                                    <span className="tech-pill tech-frontend">HTML</span>
                                    <span className="tech-pill tech-frontend">CSS</span>
                                    <span className="tech-pill tech-frontend">Bootstrap</span>
                                </div>
                                <div id='project-supersimplecheckbook' className="project-content-hide"></div>
                            </li>
                            <li onClick={() => showProject('project-laravellemon')}>
                                Laravel Lemon
                                <div id='project-laravellemon-content' className="project-content">
                                    Laravel Lemon is a skeleton (or scaffolding) 
                                    application I created on top of the PHP Laravel 
                                    framework. It has several components that I have 
                                    found useful and in the past would end up 
                                    replicating in multiple projects. A lot of the 
                                    functionality could be separated out into packages, 
                                    and I may do that in the future, but for now this 
                                    functions only as a starting point application.<br/><br/>
                                    The core functionality is a request and error 
                                    logging system, an organization - user type - user 
                                    management system, and a route access control system. 
                                    All of this functionality has basic user interface 
                                    management consoles built with VUE utilizing a child 
                                    component structure to create more modular and easily 
                                    updated interfaces. The system also uses the repository 
                                    pattern with facades and has several useful custom 
                                    functions built into the base repositories. You can 
                                    view the code on the github repository below:
                                    <br/><br/>
                                    <a className='app-link' href='https://github.com/jamesrgriffiths/laravellemon' target='blank'>Laravel Lemon Github Project</a>
                                    <br/><br/>
                                    Last Updated January 2023
                                    <br/><br/>
                                    <span className="tech-pill tech-backend">PHP</span>
                                    <span className="tech-pill tech-backend">Laravel</span>
                                    <span className="tech-pill tech-backend">Node.js</span>
                                    <span className="tech-pill tech-frontend">Vue.js</span>
                                    <span className="tech-pill tech-frontend">HTML</span>
                                    <span className="tech-pill tech-frontend">CSS</span>
                                </div>
                                <div id='project-laravellemon' className="project-content-hide"></div>
                            </li>
                            <li onClick={() => showProject('project-lemoncomponents')}>
                                Lemon Components
                                <div id='project-lemoncomponents-content' className="project-content">
                                    Lemon Components is a Vue component library for 
                                    basic website functionality and data display. It 
                                    uses sass variables for easily customizing styling 
                                    and each component is built responsively. It is at 
                                    this point in need of some dependency updates but 
                                    you can still check it out below:
                                    <br/><br/>
                                    <a className='app-link' href='https://github.com/jamesrgriffiths/lemon-components' target='blank'>Lemon Components Github Project</a>
                                    <br/><br/>
                                    Last Updated August 2020
                                    <br/><br/>
                                    <span className="tech-pill tech-backend">Node.js</span>
                                    <span className="tech-pill tech-frontend">Vue.js</span>
                                    <span className="tech-pill tech-frontend">HTML</span>
                                    <span className="tech-pill tech-frontend">CSS</span>
                                </div>
                                <div id='project-lemoncomponents' className="project-content-hide"></div>
                            </li>
                            {/* <li onClick={() => showProject('project-findyourchurch')}>
                                Find Your Church
                                <div id='project-findyourchurch-content' className="project-content">test 2</div>
                                <div id='project-findyourchurch' className="project-content-hide"></div>
                            </li> */}
                        </ul>
                    </div>
                </div></div>

            </div>

            <footer>
                &copy;2024 Shoreside Software LLC - james@shoresidesoftware.com
            </footer>

        </div>
    );
}

export default App;
